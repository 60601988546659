import { setPages } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import { Page } from 'src/app/shared/ngrx/breadcrumb/pages.model';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {NzModalService} from 'ng-zorro-antd/modal';
import {TopBreadcrumbService} from 'src/app/shared/common/services/top-breadcrumb/top-breadcrumb.service';
import {TasksService} from './service/tasks.service';
import {ActivePageService} from 'src/app/shared/common/services/active-page/active-page.service';
import {NewTaskModalComponent} from './new-task-modal/new-task-modal.component';
import {UtilService} from 'src/app/shared/util.service';
import {MessageService} from 'src/app/shared/common/services/message.service';
import {UserService} from 'src/app/shared/services/user.service';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {BreadcrumbState} from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import { RoutesEnum } from 'src/app/shared/enum/routes.enum';
import {Task} from "./interface/task-paged-response";

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<any> = new Subject();
  @ViewChild('contentTemplate') divClick!: ElementRef;
  demandId;
  userId;

  searchValue;
  filterByLicensing: string = '';
  priorityList: any[] = [];
  filterByProject: string = '';
  filterByProjects: any[] = []
  filterByLicensings: any[] = []
  filterByPriority: string = '';
  filterByDate: any = '';
  dateFrom: any = '';
  dateTo: any = '';

  dateFormat = 'dd/MM/yyyy';
  taskList:Task[] = [];
  taskListOriginal: Task[] = [];

  taskListAFA: any[] = [];
  taskListEXE: any[] = [];
  taskListCON: any[] = [];
  selectedDate: any[] = [];

  totalTasksAFA: number = 0;
  totalTasksEXE: number = 0;
  totalTasksCON: number = 0;

  dateToShowColor: number = 0;

  visible: boolean = false;
  bodyFormat: any;
  widthView: number = 0;

  customStyle = {
    background: 'white',
    'font-size': '12px',
    color: ' #FFF'
  };

  constructor(
    private readonly modal: NzModalService,
    private readonly message: MessageService,
    private route: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private topBreadcrumb: TopBreadcrumbService,
    private tasksService: TasksService,
    private userService: UserService,
    private activePage: ActivePageService,
    private utilService: UtilService,
    private store: Store<BreadcrumbState>,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.activePage.setPage('tasks');
    this.getTasks();
    this.updateBradcrumb();
    this.widthView = window.innerWidth;
    this.dispatchProjectOnStore()
  }


  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private dispatchProjectOnStore(): void {
    const pagesArray: Page[] = [
      {
        name: "Tarefas",
        url: `/${RoutesEnum.TASKS}`
      }
    ];

    this.store.dispatch(setPages({pages: pagesArray}));
  }

  replaceItalicTag(value: string): string {
    let removeItalicTag = /<\/*i>/g;
    return value.replace(removeItalicTag, "");
  }

  checkLengthString(value: string): string {
    if (value.length > 108) {
      return value + '...';
    }
    return value;
  }

  checkWindowResolution(value: string): boolean {
    if (this.widthView >= 1920) {
      return value.length > 108;
    } else {
      return value.length > 75;
    }
  }

  private getFilters() {
    this.priorityList = this.tasksService.priorityList;
    this.getFilterProjects()
    this.getFilterLicensings()
  }

  private updateBradcrumb() {
    this.route.params.subscribe((params: Params) => {
      this.demandId = +params['id'];
    });
    this.topBreadcrumb.reorgLevels({
      level: 0,
      title: 'Página Inicial',
      route: `tasks/`,
    });
    this.topBreadcrumb.reorgLevels({
      level: 1,
      title: 'Minhas Tarefas',
      route: `tasks`,
    });
  }

  getTasks(): void {
    this.taskList = []
    this.taskListAFA = []
    this.taskListCON = []
    this.taskListEXE = []
    let filters = this.getAllFilters();
    this.tasksService.getTasksList(filters).subscribe((tasks) => {
      this.taskList = tasks['content'];
      this.taskListOriginal = tasks['content'];
      this.getFilters();
      this.buildTaskList();
    });
  }

  private buildTaskList() {
    this.taskList.forEach(taskItem => {
      if (taskItem.status === 'EXE' || taskItem.status === 'AND') {
        this.buildExeList(taskItem);
      } else if (taskItem.status === 'AFA' || taskItem.status === 'INI') {
        this.buildAfaList(taskItem);
      } else {
        this.buildConList(taskItem);
      }
    });
  }

  private buildConList(taskItem) {
    const newDateDeadline = this.getNewDateDeadline(taskItem.deadline)
    taskItem.deadlinetToShow = this.formatDateNewDeadline(taskItem.deadline)
    taskItem.dateToShowColor = newDateDeadline
    taskItem.deadline = taskItem.deadline + 'T00:00:00';

    this.taskListCON.push(taskItem)

    this.totalTasksCON += 1
  }

  private buildAfaList(taskItem) {
    const newDateDeadline = this.getNewDateDeadline(taskItem.deadline)
    taskItem.deadlinetToShow = this.formatDateNewDeadline(taskItem.deadline)
    taskItem.dateToShowColor = newDateDeadline
    taskItem.deadline = taskItem.deadline + 'T00:00:00';

    this.taskListAFA.push(taskItem)

    this.totalTasksAFA += 1
  }

  private buildExeList(taskItem) {
    const newDateDeadline = this.getNewDateDeadline(taskItem.deadline)
    taskItem.deadlinetToShow = this.formatDateNewDeadline(taskItem.deadline)
    taskItem.dateToShowColor = newDateDeadline
    taskItem.deadline = taskItem.deadline + 'T00:00:00';

    this.taskListEXE.push(taskItem)
    this.totalTasksEXE += 1
  }

  private getAllFilters(): any {
    return {
      description: this.searchValue,
      projectId: this.filterByProject,
      projectLicenceId: this.filterByLicensing,
      highPriority: this.filterByPriority,
      deadLineFrom: this.dateFrom,
      deadLineTo: this.dateTo,
    }
  }

  private getNewDateDeadline(deadline: string) {
    let date1 = new Date();
    let date2 = new Date(deadline);
    date1.setHours(0, 0, 0, 0)
    let timeDiff = Math.round(date2.getTime() - date1.getTime());
    this.dateToShowColor = Math.ceil(timeDiff / (1000 * 3600 * 24))
    return this.dateToShowColor
  }

  private formatDateNewDeadline(deadline) {
    let newDate = deadline.split('-')
    newDate = (newDate[0] + '/' + newDate[1] + '/' + newDate[2]?.split("T")[0])
    return new Date(newDate)
  }

  showAddTaskModal(): void {
    const modal = this.modal.create<NewTaskModalComponent>({
      nzTitle: 'Nova tarefa',
      nzWidth: 780,
      nzContent: NewTaskModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
      nzOnCancel: (instance: NewTaskModalComponent) => new Promise((resolve, reject) => {
        if (instance.form.dirty) {
          instance.showCancelConfirm();
          reject(new Error('Network timeout'));
        }

        resolve();
      }),
    });
    modal.getContentComponent();
    modal.afterClose.subscribe(() => {
      this.getTasks();
    });
  }

  private getFilterProjects() {
    if(this.filterByProjects.length > 0) return;

    const jsonData = this.taskListOriginal;

    this.filterByProjects = [...new Map(jsonData.map(item => [item.projectId, { projectId: item.projectId, projectName: item.projectName }])).values()];
  }

  private getFilterLicensings() {
    if(this.filterByLicensings.length > 0) return;

    const jsonData = this.taskListOriginal;

    this.filterByLicensings = [...new Map(jsonData.filter(item => item.projectLicenceId).map(item => [item.projectLicenceId, { projectLicenceId: item.projectLicenceId, licenceName: item.licenceName }])).values()];
  }

  filterDateOnChange(comp: any) {
    const datas = comp.inputValue;
    this.filterByDate = datas;
    if (datas && datas.length >= 2) {
      this.dateFrom = this.formatDateForFilter(datas[0]);
      this.dateTo = this.formatDateForFilter(datas[1]);
    } else {
      this.dateFrom = '';
      this.dateTo = '';
    }
    this.getTasks();
  }

  private formatDateForFilter(data) {
    const [day1, month1, year1] = data.split('/').map(Number);
    const date1 = new Date(year1, month1 - 1, day1);
    return this.utilService.DateFormatterWithoutTime(date1.toString())
  }

  getActiveTask(section): boolean {
    return true;
  }

  alteraStatusTask(task, taskStatus): void {
    let texts = document.getElementsByClassName("ant-popover-inner-content") as HTMLCollectionOf<HTMLElement>;

    if (texts.length > 0) {
      texts[0].style.display = 'none';
    }

    const bodyReq = {
      "id": task.id,
      "status": taskStatus,
    }
    this.tasksService.updateTaskStatus(bodyReq).subscribe({
      next: () => {
        this.getTasks()
        this.message.showSucessMessage('Status alterada com sucesso!');
      },
      error: () => {
        this.tasksService.showErrorMessage('Erro ao alterar status');
      }
    })

  }

  priorityChange(idTask) {
    const newPriority = !idTask.priority
    const bodyReq = {
      "id": idTask.id,
      "priority": newPriority,
    }
    this.tasksService.updateTaskPriority(bodyReq).subscribe({
      next: () => {
        this.getTasks()
        this.message.showSucessMessage('Prioridade alterada com sucesso!');
      },
      error: () => {
        this.tasksService.showErrorMessage('Erro ao alterar prioridade');
      }
    })

  }

  onChangeDeadline(e: any, task: any) {
    let dateChange = this.utilService.DateFormatter(e).split("T")
    if (dateChange[0] !== task.deadline) {
      const bodyReq = {
        "id": task.id,
        "deadline": dateChange[0],
      }

      this.tasksService.updateTaskDeadline(bodyReq).subscribe({
        next: () => {
          this.getTasks()
          this.message.showSucessMessage('Data prazo alterada com sucesso!');
        },
        error: () => {
          this.tasksService.showErrorMessage('Erro ao alterar data prazo');
        }
      })
    }
  }

  sanitizeInputAllowOnlyItalicTag(text: string): string {
    return this.utilService.sanitizeInputAllowOnlyItalicTag(text);
  }

  goToActivity(url) {
    if (url !== null) {
      this.router.navigateByUrl(url);
    }
  }
}
